<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Lender Contact Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="createType == 'add'">Add Lender Contact</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Lender Contact Information</span
            ><span v-if="createType == 'add'">Add Lender Contact</span>
          </h3>

          <p class="py-4">
            This is where we are going to add the lender contact. A lender can
            have multiple contacts. You may also select whether the contact
            should be emailed opportunities or not.
          </p>
          <v-form
            @submit="submitCreateLenderContact"
            ref="form"
            v-model="formRules.valid"
          >
            <v-text-field
              v-model="lenderContactData.firstName"
              label="First Name"
              :rules="formRules.required"
            ></v-text-field>
            <v-text-field
              v-model="lenderContactData.lastName"
              label="Last Name"
              :rules="formRules.required"
            ></v-text-field>
            <v-text-field
              v-model="lenderContactData.email"
              label="Email"
              :rules="formRules.required"
            ></v-text-field>
            <v-text-field
              v-model="lenderContactData.phone"
              label="Phone Number"
              :rules="formRules.required"
            ></v-text-field>
            <v-select
              :items="lenderContactRoleOptions"
              v-model="lenderContactData.contactRole"
              label="Role"
              :rules="formRules.required"
            ></v-select>
       

            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="lenderContactData.active"
                  label="Active Contact"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="lenderContactData.emailOpportunities"
                  label="Email This Contact Opportunities"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateLenderContact"
                  >Create Contact
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateLenderContact"
                  >Update Contact
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'edit'"
                  @click="$router.push({ path: `/dashboard/lenderContact/${lenderContactId}` })"
                  >Cancel</v-btn
                >
                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'add'"
                  @click="$router.push({ path: `/dashboard/lender/list` })"
                  >Go To Lender List</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderContactAdd",
  props: ["lenderContactId"],
  data() {
    return {
      createType: "",
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      lenderContactData: {
        lenderId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        contactRole: "",
        active: false,
        emailOpportunities: false,
      },
      lenderContactRoleOptions: [
        { value: "bdm", text: "Business Development Manager" },
        { value: "lender", text: "Private Lender" },
        { value: "underwriter", text: "Underwriter" },
        { value: "administrator", text: "Administrator" },
        { value: "assistant", text: "Assistant" },
      ],
    };
  },
  methods: {
    submitCreateLenderContact() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/lenderContact", this.lenderContactData)
          .then((res) => {
            this.formLoading = false;

            this.$router.push(
              `/dashboard/lenderContacts/read/${res.data.insertedLenderContactId}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    submitUpdateLenderContact() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {

      
        API()
          .patch(
            `/lenderContact/${this.lenderContactData.id}`,
            this.lenderContactData
          )
          .then((res) => {
          
            this.formLoading = false;

            this.$router.push(
              `/dashboard/lenderContacts/read/${this.lenderContactData.id}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "lenderContactAdd") {
      this.createType = "add";
      this.lenderContactData.lenderId = parseInt(this.$route.query.lenderId);
      this.componentLoading = false;
    } else {
      this.createType = "edit";
      API()
        .get(`/lenderContact/read/${this.lenderContactId}`)
        .then((res) => {
          this.lenderContactData = res.data.recordset[0];
          this.componentLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>